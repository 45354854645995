
















import { Component, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";

@Component({
  components: {
    News: () => import("@/app/modules/news/components/NewsComponent.vue")
  }
})
export default class NewsPage extends Vue {
  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView("News");
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get newsData() {
    return this.$store.getters["news/data"];
  }

  created() {
    if (this.$route.params?.from != "push") {
      this.$store.dispatch("news/fetch").then(({ data }) => {
        this.$store.commit("news/setData", data.data);
      });
    }
  }
}
